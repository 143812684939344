/** @jsx jsx */
import { jsx } from "@theme-ui/core";
import { useState } from "react";
import PropType from "prop-types";
import { InView } from "react-intersection-observer";

import { easeInOutQuint } from "../../../utils/styles";

const speedMap = {
  slow: "1s",
  normal: "0.6s",
  fast: "0.3s",
};

const AnimateInView = ({
  children,
  as,
  threshold,
  speed,
  delay,
  x,
  y,
  opacity,
  triggerOnce,
  ...otherProps
}) => {
  const [isInView, setInView] = useState(false);
  const updateVisibility = (inView) => {
    setInView(inView);
  };

  return (
    <InView
      as={as}
      onChange={(inView) => updateVisibility(inView)}
      threshold={threshold}
      triggerOnce={triggerOnce}
      {...otherProps}
    >
      <div
        sx={{
          opacity: isInView ? 1 : opacity,
          transform: `translate(${isInView ? 0 : x}, ${isInView ? 0 : y})`,
          transition: `${speedMap[speed]} ${easeInOutQuint}`,
          transitionDelay: `${delay}s`,
          willChange: "transform, opacity",
        }}
      >
        {children}
      </div>
    </InView>
  );
};

AnimateInView.propTypes = {
  children: PropType.node.isRequired,
  as: PropType.string,
  threshold: PropType.number,
  speed: PropType.oneOf(Object.keys(speedMap)),
  delay: PropType.number,
  x: PropType.string,
  y: PropType.string,
  triggerOnce: PropType.bool,
};

AnimateInView.defaultProps = {
  as: "div",
  threshold: 0.1,
  speed: "normal",
  delay: 0,
  x: "0",
  y: "50px",
  opacity: 0,
  triggerOnce: true,
};

export default AnimateInView;
