import React from "react";

import GridRow from "../../commons/GridRow";
import Box from "../../commons/Box";
import Button from "../../commons/Button";
import Heading from "../../commons/Heading";
import AnimateInView from "../../commons/AnimateInView";
import { linkResolver } from "../../../utils/links";

export default function FadeInListBlock({
  subtitle,
  buttons,
  list,
  ...otherProps
}) {
  return (
    <GridRow
      as="section"
      sx={{
        pt: [5, "gridGap"],
        pb: [6, 7],
        background: "black",
        color: "inverted",
        textAlign: ["center", "start"],
        rowGap: 5,
      }}
    >
      <GridRow.Col gridColumn={["start / end", "start / span 6"]}>
        <Heading as="h4" variant="h3" sx={{ px: [3, 0], mb: [3, 5] }}>
          {subtitle}
        </Heading>
        {!!buttons.length && (
          <Box sx={{ mt: 4 }}>
            {buttons.map(linkResolver).map((link) => (
              <Button variant="gradient" key={link.to || link.href} {...link} />
            ))}
          </Box>
        )}
      </GridRow.Col>
      {list.map((item, i) => (
        <GridRow.Col key={item._key} sx={{ mt: [4, 5] }}>
          <AnimateInView
            delay={i * 0.2}
            speed="slow"
            y="5em"
            opacity={0.1}
            threshold={0.5}
          >
            <GridRow subGrid sx={{ alignItems: "center" }}>
              <GridRow.Col gridColumn={["2 / -2", "start / span 6"]}>
                <Heading as="h3" variant="h1" sx={{ mt: [0, "-0.3em"] }}>
                  {item.label}
                </Heading>
              </GridRow.Col>
              <GridRow.Col gridColumn={["3 / -3", "7 / end"]}>
                <Heading as="p" variant="h4" sx={{ fontSize: "body" }}>
                  {item.value}
                </Heading>
              </GridRow.Col>
            </GridRow>
          </AnimateInView>
        </GridRow.Col>
      ))}
    </GridRow>
  );
}
